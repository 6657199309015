import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-48e55a90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "question" }
const _hoisted_2 = { class: "select-container" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["name", "id"]
const _hoisted_5 = ["value", "selected", "disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.question.label), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("label", {
        for: _ctx.question.id
      }, [
        _createElementVNode("select", {
          name: _ctx.question.id,
          id: _ctx.question.id
        }, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.options, (option, index) => {
            return (_openBlock(), _createElementBlock("option", {
              key: _ctx.question.id + '-option-' + index,
              value: option.responseIndex,
              selected: index == 0,
              disabled: index == 0
            }, _toDisplayString(option.responseIndex), 9, _hoisted_5))
          }), 128))
        ], 8, _hoisted_4)
      ], 8, _hoisted_3)
    ])
  ]))
}