
import { defineComponent } from 'vue';
import Header from '@/components/Header.vue';
import JobFamiliesComp from '@/components/JobFamiliesComp.vue';
import Footer from '@/components/Footer.vue';

export default defineComponent({
  name: 'JobFamilies',
  components: {
    Header,
    JobFamiliesComp,
    Footer,
  },
});
