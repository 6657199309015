
import { defineComponent, PropType } from 'vue';
import { mapState, mapGetters } from 'vuex';
import { Question } from '@/lib/interfaces';

export default defineComponent({
  name: 'QuestionSelect',
  props: {
    question: {
      type: Object as PropType<Question>,
      required: true,
    },
  },
  computed: {
    ...mapState(['responses']),
    ...mapGetters(['getResponseByQuestionId']),
  },
});
