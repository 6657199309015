/* eslint-disable global-require */
/* eslint-disable max-len */
import PlainContent from '@/types/PlainContent';

const JobFamiliesContent: PlainContent = {
  title: 'Job Families',
  body: '<h3>Select your chosen job family</h3>',
};

export default JobFamiliesContent;
