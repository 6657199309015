
import { defineComponent, computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import PlainContent from '../types/PlainContent';
import JobFamiliesContent from '@/content/JobFamiliesContent';
import ShapeMask from '@/components/atoms/ShapeMask.vue';
import QuestionSelect from '@/components/QuestionSelect.vue';
import JobFamiliesData from '@/data/job-families.json'; // data populated from here.
import { JobSectors } from '@/content/JobSectorContent';

interface Option {
  responseIndex: string;
}

interface RootObject {
  id: string;
  options: Option[];
}

export default defineComponent({
  name: 'JobFamiliesComp',
  components: {
    ShapeMask,
    QuestionSelect,
  },
  setup() {
    const content: PlainContent = JobFamiliesContent;
    const router = useRouter();

    const jobFamiliesQuestion = computed(() => {
      const jobFamilies: RootObject = {
        id: 'jobFamilies',
        options: [],
      };
      Object.keys(JobFamiliesData).forEach((key) => {
        jobFamilies.options.push({ responseIndex: key });
      });
      jobFamilies.options.sort((a, b) => (a.responseIndex.localeCompare(b.responseIndex)));
      jobFamilies.options.unshift({ responseIndex: 'Select...' });
      return jobFamilies;
    });

    const formSubmit = (event: any) => {
      const selectedFamily = event.target.querySelector('select').value;
      const jobSectors: {[index: string]:any} = JobSectors;
      Object.keys(jobSectors).forEach((sector) => {
        jobSectors[sector].families.forEach((family: any, index: number) => {
          if (family.title === selectedFamily) {
            router.push({ path: `/job-sector/${sector}`, query: { current: `${index + 1}` } });
          }
        });
      });
    };

    const btnDisabled = ref(true);
    const selectChange = () => {
      btnDisabled.value = false;
    };

    return {
      content,
      formSubmit,
      jobFamiliesQuestion,
      btnDisabled,
      selectChange,
    };
  },
});
